<!--
 Name: Home.vue
 Description: Home page component.
 Created by: Milena Malysheva on 2020-09-03
 Last edited by: Jana Schumann on 2024-01-09
-->

<template>
  <v-container fluid class="pa-0">
    <news-banner/>

    <!-- hero -->
    <app-hero
      :slides="heroSlides"
    >
    </app-hero>

    <!-- page title -->
    <h1 class="text-center pt-lg-16 pb-lg-12 py-6 font-weight-semi-bold title-font padded-block" id="top">
      {{$t('home.title')}}
    </h1>

    <!-- introduction -->
    <i18n-custom
      class="subtitle-font large-line-height padded-block text-md-justify"
      tag="p"
      path="home.intro"
    />

    <!-- call to action -->
    <v-img
      class="mt-14"
      height="650"
      src="@/assets/images/home/cta_background.svg"
    >
      <v-row
        justify="center"
        align="end"
        class="padded-block fill-height pb-12"
        no-gutters
      >
        <v-col cols="10" sm="6" md="4" lg="3">
          <!-- BTN: discover projects and research -->
          <v-btn
            x-large
            outlined
            rounded
            block
            color="primary"
            height="60"
            :ripple="false"
            to="/projects#top"
          >
            {{ $t("home.ctaBtn") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-img>

    <!-- citizen science principle -->
    <!-- title -->
    <p class="text-center py-8 font-weight-semi-bold title-font padded-block">
      {{$t('home.ciSciPrinciple.title')}}
    </p>
    <!-- scheme for large devices -->
    <v-img
      height="650"
      contain
      src="@/assets/images/home/cisci_schema.svg"
      v-if="$vuetify.breakpoint.mdAndUp"
    />
    <!-- scheme for small devices -->
    <v-img
      height="500"
      contain
      src="@/assets/images/home/cisci_schema_mobile.svg"
      v-else
    />

    <!-- BTN: watch video -->
    <!-- we don't use dialog's activator here because it messes up vue-router scrolling behaviour -->
    <v-row justify="center" class="py-12 mt-10 padded-block lightGreen" no-gutters>
      <v-col cols="10" sm="6" md="4" lg="3">
        <v-btn
          x-large
          outlined
          rounded
          color="primary"
          block
          height="60"
          :ripple="false"
          @click.stop="videoDialog = true"
        >
          {{$t('home.ciSciPrinciple.watchVideoBtn')}}
        </v-btn>
      </v-col>
    </v-row>

    <!-- video pop-up -->
    <v-dialog
      max-width="1200"
      v-model="videoDialog"
    >
      <!-- dialog card -->
      <v-card class="pa-3">
        <!-- v-if is required to stop the video once the dialog is closed -->
        <video
          controls
          v-if="videoDialog"
          width="100%"
          autoplay
        >
          <source src="@/assets/videos/cisci_video.mp4" type="video/mp4">
          Sorry, your browser doesn't support embedded videos.
        </video>
      </v-card>
    </v-dialog>

    <!-- install the app -->
    <p class="text-center py-8 mb-0 font-weight-semi-bold title-font padded-block" id="app">
      {{$t('home.installApp.title')}}
    </p>
    <i18n
      class="padded-block text-md-justify mb-7"
      path="home.installApp.description"
      tag="p"
    >
      <template #neotiv-link>
        <a
          href="https://neotiv.com/de"
          class="bleuCanard--text text-decoration-none"
          target="_blank"
        >neotiv.com</a>
      </template>
    </i18n>

    <!-- install the app links -->
    <v-container class="padded-block mb-11">
      <v-row justify="center">

        <!-- app store -->
        <v-col cols="auto">
          <a href="https://apps.apple.com/de/app/neotivtrials/id1611173081"
             rel="noopener noreferrer"
             target="_blank"
          >
            <v-img
              class="app-link"
              max-width="150"
              max-height="50"
              contain
              src="@/assets/images/home/app_links/app_store.png"
            />
          </a>
        </v-col>

        <!-- google play -->
        <v-col cols="auto">
          <a href="https://play.google.com/store/apps/details?id=com.neotiv.app.trials"
             rel="noopener noreferrer"
             target="_blank"
          >
            <v-img
              class="app-link"
              max-width="150"
              max-height="50"
              contain
              src="@/assets/images/home/app_links/google_play.png"
            />
          </a>
        </v-col>
      </v-row>
    </v-container>

    <!-- IBL: partners -->
    <app-info-block
      class="padded-block pt-12 pb-6 lightGrey"
      text-position="left"
      :block-title="$t('home.partners.0.title')"
      :block-image="require('@/assets/images/home/partner_grimm_benne.png')"
      block-image-width="100%"
    >
      <template #text-content>
        <i18n-custom
          paragraph-class="mb-3"
          tag="p"
          path="home.partners.0.text"
        />
        <p class="font-weight-semi-bold mb-0">{{ $t('home.partners.0.author') }}</p>
        <p class="job">{{ $t('home.partners.0.job') }}</p>
      </template>
    </app-info-block>
    <app-info-block
      class="padded-block pt-6 pb-12 lightGrey"
      text-position="right"
      :block-title="$t('home.partners.1.title')"
      :block-image="require('@/assets/images/home/partner_haseloff.png')"
      block-image-width="100%"
    >
      <template #text-content>
        <p class="mb-3">
          {{ $t('home.partners.1.text') }}
        </p>
        <p class="font-weight-semi-bold mb-0">{{ $t('home.partners.1.author') }}</p>
        <p class="job">{{ $t('home.partners.1.job') }}</p>
      </template>
    </app-info-block>
  </v-container>
</template>

<script>
  import NewsBanner from "../components/NewsBanner";

  export default {
    components: {
      NewsBanner
    },
    data: () => ({
      heroSlides: [],
      videoDialog: false
    }),
    mounted() {
      this.heroSlides = this.$t('home.hero').map(
        (_, idx) => ({
          image: require(`@/assets/images/home/hero/hero_${idx}.jpg`),
          textPath: `home.hero.${idx}`
        })
      )
    }
  };
</script>

<style scoped>
  /* app links */
  .app-link:hover {
    opacity: 0.5;
  }

  /* partners */
  .job {
    font-size: 16px;
  }
</style>
