/**
 * Name: index.js
 * Description: Router configuration file.
 * Created by: Milena Malysheva on 2020-09-03
 * Last edited by: Milena Malysheva on 2020-10-15
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Projects from '@/views/projects/ProjectList.vue'
import ProjectTemplate from '@/views/projects/ProjectTemplate.vue'
import Blog from '@/views/blog/Blog.vue'
import BlogArticleTemplate from '@/views/blog/BlogArticleTemplate.vue'
import Contact from '@/views/Contact.vue'
import Legal from '@/views/Legal.vue'
import DataProtection from '@/views/DataProtection.vue'
import PageNotFound from '@/views/PageNotFound.vue'

import projects from '@/data/projects.json';
import articles from '@/data/articles.json';

Vue.use(VueRouter)

const router = new VueRouter({
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        // 96 - height of the header
        offset: { x: 0, y: 96 }
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // Startseite (links in footer/header)
    {
      path: '/',
      alias: '/home',
      name: 'Home',
      component: Home
    },
    // Mitforschen (link header)
    {
      path: '/projects',
      name: 'Projects',
      component: Projects
    },
    // Projekt
    {
      path: `/projects/:id([0-${projects.length - 1}])`,
      name: 'ProjectTemplate',
      component: ProjectTemplate
    },
    // Blog (link header)
    {
      path: '/blog',
      name: 'Blog',
      component: Blog
    },
    // Artikel
    {
      path: `/blog/:id([0-${articles.length - 1}])`,
      name: 'BlogArticleTemplate',
      component: BlogArticleTemplate
    },
    // Kontaktformular (link footer)
    {
      path: '/contact',
      name: 'Contact',
      component: Contact
    },
    // Impressum (link footer)
    {
      path: '/legal',
      name: 'Legal',
      component: Legal
    },
    // Datenschutzerklärung (link footer)
    {
      path: '/data',
      name: 'DataProtection',
      component: DataProtection
    },

    // 404 Not found
    {
      path: '*',
      name: 'error',
      component: PageNotFound
    }
  ]
})

export default router
