<!--
 Name: TheHeader.vue
 Description: Site header component.
 Created by: Milena Malysheva on 2020-09-03
 Last edited by: Milena Malysheva on 2021-11-05
-->

<template>
  <!-- if the height will be changed in the future don't forget to change y-offset in the router!!! -->
  <v-app-bar
    app
    class="app-header padded-block"
    height="96"
  >

    <!-- logo -->
    <router-link to="/">
      <!-- height is required to make it work correctly in firefox -->
      <v-img
        height="100"
        width="450"
        contain
        class="hidden-md-and-down"
        src="@/assets/images/logo_large.svg"
      />
      <v-img
        height="62"
        width="62"
        contain
        src="@/assets/images/logo.svg"
        class="hidden-lg-and-up ml-1"
      />
    </router-link>

    <v-spacer/>

    <!-- menu items -->
    <router-link
      v-for="link in menuLinks"
      :key="link"
      :to="`/${link}`"
      class="hidden-sm-and-down router-link text-decoration-none ml-8"
    >
      <p class="mb-0">{{$t(`siteMenu.${link}`)}}</p>
    </router-link>

    <!-- button used to open the side menu on small devices -->
    <!-- v-app-bar-nav-icon with an icon inside doesn't work adequately (v-toolbar__content cuts the icon a bit) -->
    <v-icon
      size="49"
      @click="$emit('update:openSideMenu', true)"
      class="hidden-md-and-up mr-1"
      color="black"
    >
      mdi-menu
    </v-icon>
  </v-app-bar>
</template>

<script>
  export default {
    props: {
      openSideMenu: Boolean,
      menuLinks: Array
    }
  };
</script>

<style scoped>
  /* vuetify default app structure doesn't support max-width, centered layouts */
  /* https://github.com/vuetifyjs/vuetify/issues/4267 */
  /* vuetify uses inline styles for the left/right properties :( */
  .app-header {
    left: auto!important;
    right: auto!important;
    width: 100%;
    max-width: inherit;
  }

  .app-header ::v-deep .v-toolbar__content {
    padding: 0;
  }

  /* router links */
  .router-link {
    font-size: 20px;
  }

  .router-link:hover {
    color: var(--v-grey2-base)
  }

</style>