<!--
 Name: CookieBanner.vue
 Description: Component to display the cookie banner.
 Created by: Milena Malysheva on 2020-09-17
 Last edited by: Milena Malysheva on 2021-11-03
-->

<template>
  <v-dialog
    :transition="false"
    v-model="dialog"
    persistent
    scrollable
    max-width="650"
    overlay-opacity="0.7"
    overlay-color="popupGreyBg"
  >
    <v-card class="pa-7">
      <v-card-title class="mb-4 pa-0">
        <b>{{ $t("cookieBanner.title") }}</b>
      </v-card-title>
      <!-- mr-n7 is a workaround to put the scrolling bar to the rightmost position -->
      <v-card-text class="cookieBannerText--text py-0 pl-0 pr-7 mr-n7">
        <i18n path="cookieBanner.text" tag="p" class="cookieBannerText--text">
          <template #privacy-policy-link>
            <router-link
                    to="/data"
                    target="_blank"
                    class="primary--text"
            >{{ $t("cookieBanner.privacyPolicyLink") }}</router-link>
          </template>
        </i18n>

        <!-- checkboxes -->
        <v-row no-gutters class="mt-4">
          <v-checkbox
            class="mr-4"
            v-for="(_, group) in groups"
            v-model="groups[group]"
            :key="`checkbox-${group}`"
            :disabled="group === 'essential'"
            :label="$t(`cookieBanner.groups.${group}.title`)"
          >
          </v-checkbox>
        </v-row>

        <v-divider/>

        <!-- show / hide details button -->
        <v-row no-gutters>
          <v-btn
            @click="showDetails = !showDetails"
            text
            class="primary--text px-0 my-3"
            :ripple="false"
          >
            <v-icon
              v-html="showDetails ? 'mdi-chevron-up' : 'mdi-chevron-down'"
              class="mr-2"
            />
            {{ $t(`cookieBanner.${showDetails ? 'hideDetailsBtn' : 'showDetailsBtn'}`) }}
          </v-btn>
        </v-row>

        <!-- details -->
        <template v-if="showDetails">
          <template v-for="(_, group) in groups">
            <b :key="`details-title-${group}`">
              {{ $t(`cookieBanner.groups.${group}.title`) }}
            </b>
            <p :key="`details-description-${group}`" class="mb-7">
              {{ $t(`cookieBanner.groups.${group}.description`) }}
            </p>
          </template>
        </template>
      </v-card-text>

      <!-- fixed dialog footer -->
      <v-divider/>

      <!-- div wrapper to make it work on iPhone 7/8 -->
      <div>
        <v-row
          justify="end"
          class="pt-7 pb-11"
        >
          <v-col cols="12" sm="5">
            <v-btn
              large
              outlined
              block
              color="primary"
              @click="confirmSelection()"
              :disabled="!groups.essential"
            >
              {{ $t(`cookieBanner.confirmSelectionBtn`) }}
            </v-btn>
          </v-col>

          <v-col cols="12" sm="5">
            <v-btn
              large
              block
              color="primary"
              @click="selectAll()"
            >
              {{ $t(`cookieBanner.selectAllBtn`) }}
            </v-btn>
          </v-col>
        </v-row>

        <!-- links legal -->
        <div class="bottom-link cookieBannerText--text">
          <router-link
            to="/legal"
            target="_blank"
            class="cookieBannerText--text"
          >{{ $t("footer.legal") }}</router-link>
          |
          <router-link
            to="/data"
            target="_blank"
            class="cookieBannerText--text"
          >{{ $t("footer.data") }}</router-link>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    groups: {
      essential: true,
      statistics: false
    },
    showDetails: false
  }),
  methods: {
    selectAll() {
      // we want to show the checkboxes checked before the dialog is closed
      for (let group of Object.keys(this.groups)) {
        this.groups[group] = true
      }
      this.confirmSelection()
    },
    confirmSelection() {
      for (let [group, accepted] of Object.entries(this.groups)) {
        if (accepted) {
          localStorage.setItem(`cookie:${group}`, "true");
        } else {
          localStorage.removeItem(`cookie:${group}`);
        }
      }
      localStorage.setItem("cookie:policyVersion", process.env.VUE_APP_DATA_USE_POLICY_VERSION);
      // reloads the page
      // can be removed when _paq.push(['enableCookies']) will be implemented: https://github.com/matomo-org/matomo/issues/13056
      this.$router.go(0)
    },
    resetCookieLocalStorage() {
      for (let group of Object.keys(this.groups)) {
        localStorage.removeItem(`cookie:${group}`);
      }
    },
    setCheckboxes() {
      for (let group of Object.keys(this.groups)) {
        this.groups[group] = localStorage.getItem(`cookie:${group}`)
      }
      this.groups.essential = true
    }
  },
  mounted() {
    if (localStorage.getItem('cookie:policyVersion') !== process.env.VUE_APP_DATA_USE_POLICY_VERSION) {
      this.resetCookieLocalStorage();
    }
    this.setCheckboxes()
    this.dialog =
      this.$route.name !== "Legal" &&
      this.$route.name !== "DataProtection" &&
      !localStorage.getItem("cookie:essential");
  }
};
</script>
<style scoped>
  .bottom-link {
    font-size: 16px;
  }

  .v-dialog--scrollable > .v-card > .v-card__text {
    width: auto;
  }
</style>
