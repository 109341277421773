<!--
 Name: DataProtection.vue
 Description: Component for the data protection page.
 Created by: Milena Malysheva on 2020-09-08
 Last edited by: Jana Schumann on 2023-06-02
-->

<template>
  <v-container fluid class="pa-0 mb-12">
    <div class="padded-block">
      <!-- page title -->
      <h1 class="py-10 font-weight-semi-bold title-font" id="top">
        {{$t('footer.data')}}
      </h1>

      <!-- intro -->
      <p class="large-line-height pb-8">
        {{$t('dataProtection.intro')}}
      </p>

      <!-- content -->
      <div
        v-for="(block, idx) in $t('dataProtection.content')"
        :key="`dataProtection.${idx}`"
        class="pb-4"
      >
        <h2 class="subtitle-font font-weight-semi-bold">
          {{block.title}}
        </h2>
        <i18n-custom
          :path="`dataProtection.content.${idx}.text`"
          tag="p"
          class="large-line-height pt-1"
        >
          <!-- neotiv data protection officer-->
          <template #security-email>
            <a
              href="mailto:datenschutz@heydata.eu"
              class="bleuCanard--text"
            >datenschutz@heydata.eu</a>
          </template>

          <!-- Saxony-Anhalt data protection (website) -->
          <template #data-st-website>
            <a
              href="https://datenschutz.sachsen-anhalt.de"
              class="bleuCanard--text"
              rel="noopener noreferrer"
              target="_blank"
            >datenschutz.sachsen-anhalt.de</a>
          </template>

          <!-- Saxony-Anhalt data protection (email) -->
          <template #data-st-email>
            <a
              href="mailto:poststelle@lfd.sachsen-anhalt.de"
              class="bleuCanard--text"
            >poststelle@lfd.sachsen-anhalt.de</a>
          </template>

          <!-- cookies table -->
          <template #cookies>
            <v-data-table
              class="cookies mt-5"
              :headers="headers"
              :items="cookies"
              hide-default-footer
            >
            </v-data-table>
          </template>

          <!-- change cookie settings link -->
          <template #opt-out-link>
            <router-link to="/" class="bleuCanard--text">
              <span @click="resetCookieLocalStorage">{{ $t("dataProtection.optOutLink") }}</span>
            </router-link>
          </template>
        </i18n-custom>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    methods: {
      resetCookieLocalStorage() {
        // the cookie banner will be shown and the non-essential cookie checkboxes will remain checked if they were checked before
        localStorage.removeItem('cookie:essential')
      }
    },
    computed: {
      headers () {
        return Object.entries(this.$t("dataProtection.cookies.tableHeaders")).map(([key, value]) => ({
            value: key,
            text: value,
            sortable: false,
            width: key === "purpose" ? "30%" : "10%"
          })
        )
      },
      cookies () {
        return [
          {
            name: "_pk_id",
            hoster: "neotiv GmbH",
            purpose: this.$t("dataProtection.cookies.purpose._pk_id"),
            optin: this.$t("dataProtection.cookies.optin.yes"),
            expire: this.$tc("dataProtection.cookies.expire.year", 1)
          },
          {
            name: "_pk_ses",
            hoster: "neotiv GmbH",
            purpose: this.$t("dataProtection.cookies.purpose._pk_ses"),
            optin: this.$t("dataProtection.cookies.optin.yes"),
            expire: this.$tc("dataProtection.cookies.expire.minute", 30)
          }
        ];
      }
    }
  };
</script>

<style scoped>
  >>> .cookies table thead th {
    vertical-align: top;
  }
</style>
