<!--
 Name: AppInfoBlock.vue
 Description: Info block component.
 Created by: Milena Malysheva on 2020-09-08
 Last edited by: Milena Malysheva on 2020-12-16
-->

<template>
    <v-row no-gutters justify="space-between">
      <!-- info block text content -->
      <v-col
        cols="12"
        :md="textPosition === 'right' || textPosition === 'left' ? 5 : 12"
      >

        <!-- square -->
        <div class="info-block-square primary mb-5"></div>

        <!-- title & subtitle -->
        <div class="mb-3">
          <h2 class="font-weight-semi-bold subtitle-font">
            {{blockTitle}}
          </h2>
          <p
            v-if="blockSubTitle"
            class="grey2--text"
          >
            {{blockSubTitle}}
          </p>
        </div>

        <!-- content -->
        <slot name="text-content"></slot>
      </v-col>

      <!-- info block image -->
      <v-col
        v-if="textPosition === 'right' || textPosition === 'left'"
        class="mt-6 mt-md-0"
        cols="12"
        md="5"
        order="last"
        :order-md="textPosition === 'right' ? 'first' : 'last'"
      >
        <v-img
          :src="blockImage"
          :height="blockImageHeight"
          :width="blockImageWidth"
          contain
        />
      </v-col>
    </v-row>
</template>

<script>
  export default {
    props: {
      textPosition: String,
      blockTitle: String,
      blockSubTitle: String,
      blockImage: String,
      blockImageHeight: Number,
      blockImageWidth: [String, Number]
    }
  };
</script>

<style scoped lang="scss">
  @import '~vuetify/src/styles/styles.sass';

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    .info-block-square {
      width: 50px;
      height: 7px;
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .info-block-square {
      width: 35px;
      height: 7px;
    }
  }
</style>