<!--
 Name: App.vue
 Description: Application root.
 Created by: Milena Malysheva on 2020-09-03
 Last edited by: Milena Malysheva on 2021-11-29
-->

<template>
  <v-app id="app-root">

    <!-- side menu shown only on small devices -->
    <the-side-menu
      class="hidden-md-and-up"
      :openSideMenu.sync="openSideMenu"
      :menuLinks="menuLinks"
    />

    <!-- header (with the menu shown on large devices) -->
    <the-header
      :openSideMenu.sync="openSideMenu"
      :menuLinks="menuLinks"
    />

    <!-- page content -->
    <v-main class="white">
      <router-view :key="$route.fullPath"/>
    </v-main>

    <!-- footer -->
    <the-footer/>

    <!-- cookie banner -->
    <cookie-banner :key="$route.path" />
  </v-app>
</template>

<script>
  import TheSideMenu from '@/components/layout/TheSideMenu';
  import TheHeader from '@/components/layout/TheHeader';
  import TheFooter from '@/components/layout/TheFooter';
  import CookieBanner from "@/components/CookieBanner";

  export default {
    name: 'App',
    components: {
      TheSideMenu,
      TheHeader,
      TheFooter,
      CookieBanner
    },
    data: () => ({
      openSideMenu: false,
      menuLinks: ['home', 'projects', 'blog']
    })
  };
</script>

<style scoped lang="scss">
  @import '~vuetify/src/styles/styles.sass';
  @import './assets/css/fonts.css';

  /* App layout */
  #app-root {
    background-color: var(--v-lightGray-base);
  }
  /* vuetify default app structure doesn't support max-width, centered layouts */
  /* https://github.com/vuetifyjs/vuetify/issues/4267 */
  /* min-width: 0; is required to make the running text work on the small laptops 110% zoom*/
  /* min-width: 0; also fixes images on the small devices in safari (in the other websites we use a fix with
  .v-responsive__content since min-width: 0 works badly when there is a long text in the hero components -> a page
  become wider and the buttons don't scale to fit a long text inside them */
  ::v-deep .v-application--wrap {
    max-width: 1440px;
    min-width: 0;
    margin: auto;
  }

  /* Global styles*/
  ::v-deep .v-btn--outlined {
    background-color: white;
  }

  /* Globally used classes */
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    ::v-deep .padded-block {
      padding-left: 120px;
      padding-right: 120px;
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    ::v-deep .padded-block {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
</style>