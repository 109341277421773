<!--
 Name: I18nCustom.vue
 Description: i18n custom wrapper for the multiline text.
 Created by: Milena Malysheva on 2022-01-19
 Last edited by: Milena Malysheva on 2022-01-19
-->

<template>
  <div>
    <i18n
      v-bind="$attrs"
      v-on="$listeners"
      :class="paragraphClass"
      v-for="idx in (Array.isArray(paragraphs) ? paragraphs : [paragraphs]).length"
      :key="`${path}.${idx - 1}`"
      :path="Array.isArray(paragraphs) ? `${path}.${idx - 1}` : path"
    >
      <template v-for="(_, name) in $slots" v-slot:[name]>
        <slot :name="name"/>
      </template>
      <template #br>
        <br>
      </template>
      <template #shy>&shy;</template>
    </i18n>
  </div>
</template>

<script>
  export default {
    data: () => ({
      paragraphs: null
    }),
    props: {
      path: {
        type: String,
        required: true
      },
      paragraphClass: {
        type: String,
        default: ''
      },
    },
    mounted() {
      this.paragraphs = this.$t(this.path)
    }
  };
</script>