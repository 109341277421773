<!--
 Name: BlogArticleTemplate.vue
 Description: Blog article template.
 Created by: Milena Malysheva on 2020-09-17
 Last edited by: Milena Malysheva on 2023-02-01
-->

<template>
  <v-container fluid class="pa-0">

    <!-- navigation toolbar -->
    <v-row
      class="duckBlueWater navigation-toolbar padded-block py-3"
      align="center"
      no-gutters
    >

      <!-- previous article -->
      <v-col cols="4">
        <component
          :is="nextArticleId === null ? 'label' : 'router-link'"
          class="text-decoration-none"
          :class="nextArticleId === null ? 'grey2--text' : 'primary--text'"
          :to="`/blog/${nextArticleId}`"
        >
          <v-icon
            class="mr-3"
            large
          >
            mdi-chevron-left
          </v-icon>
          <span
            :class="{'text-decoration-underline': nextArticleId !== null}"
            class="hidden-sm-and-down">{{ $t("blog.navigation.nextArticle") }}</span>
        </component>
      </v-col>

      <!-- all articles -->
      <v-col
        cols="4"
        class="text-center"
      >
        <router-link
          to="/blog"
          class="primary--text"
        >{{ $t("blog.navigation.allArticles") }}</router-link>
      </v-col>

      <!-- next article -->
      <v-col
        cols="4"
        class="text-right"
      >
        <component
          :is="prevArticleId === null ? 'label' : 'router-link'"
          class="text-decoration-none"
          :class="prevArticleId === null ? 'grey2--text' : 'primary--text'"
          :to="`/blog/${prevArticleId}`"
        >
          <span
            :class="{'text-decoration-underline': prevArticleId !== null}"
            class="hidden-sm-and-down">{{ $t("blog.navigation.previousArticle") }}</span>
          <v-icon
            class="ml-3"
            large
          >
            mdi-chevron-right
          </v-icon>
        </component>
      </v-col>
    </v-row>

    <div class="padded-block">
      <!-- article title -->
      <h1 class="font-weight-semi-bold title-font pt-6" id="top">
        {{ article.title }}
      </h1>

      <!-- article info -->
      <p class="grey2--text pb-9">
        {{ article.author }} · {{ article.date }}
      </p>


      <!-- article content -->
      <v-row>
        <v-col cols="12" md="9">
          <component :is="'article' + article.id"/>
        </v-col>
      </v-row>

      <!-- disclaimer -->
      <div class="duckBlueWater primary--text pa-6 mb-12 mt-7">
        {{ article.disclaimer }}
      </div>
    </div>
  </v-container>
</template>

<script>
  import articles from '@/data/articles.json';
  import * as articleComponents from '@/components/articles'

  export default {
    components: {
      ...articleComponents
    },
    data: () => ({
      article: null,
      prevArticleId: null,
      nextArticleId: null
    }),
    created() {
      const currentArticleIdx = articles.findIndex(article => parseInt(this.$route.params.id, 10) === article.id)
      this.nextArticleId = currentArticleIdx ? articles[currentArticleIdx - 1].id : null
      this.prevArticleId = currentArticleIdx === articles.length - 1 ? null : articles[currentArticleIdx + 1].id

      this.article = {
        ...articles[currentArticleIdx],
        ...this.$t(`blog.articles.${this.$route.params.id}`),
        disclaimer: this.$t(`blog.articles.${this.$route.params.id}.disclaimer`,
          { author: articles[currentArticleIdx].author }
        )
      }
    }
  };
</script>

<style scoped>
  .navigation-toolbar a{
    cursor: pointer;
  }
  .navigation-toolbar a:hover{
    opacity: 0.6;
  }
  .navigation-toolbar .v-icon {
    color: inherit;
  }
</style>