<!--
 Name: ProjectList.vue
 Description: Project list component.
 Created by: Milena Malysheva on 2020-09-11
 Last edited by: Milena Malysheva on 2021-12-17
-->

<template>
  <v-container fluid class="pa-0">
<!--    <app-hero-->
<!--      :slides="heroSlides"-->
<!--    >-->
<!--    </app-hero>-->

    <!-- page title -->
    <h1 class="text-center pt-lg-16 pb-lg-12 py-6 font-weight-semi-bold title-font padded-block" id="top">
      {{$t('projects.title')}}
    </h1>

    <!-- introduction -->
    <p class="subtitle-font padded-block text-md-justify mb-7">
      {{$t('projects.intro')}}
    </p>

    <!-- grid -->
    <!-- doesn't look good on mobile devices without the container -->
    <v-container class="pb-15 padded-block" fluid>
      <v-row>
        <v-col
          cols="12" md="6" lg="4"
          v-for="project in projects"
          :key="project.id"
        >
          <v-card
            tile
            height="fit-content"
            min-height="530"
            class="lightGrey2 d-flex flex-column"
          >

            <!-- project logo -->
            <router-link
              :to="`/projects/${project.id}`"
            >
              <v-img
                :src="require(`@/assets/images/projects/logos/project_${project.id}.jpg`)"
                max-height="220"
                aspect-ratio="1.5"
              />
            </router-link>

            <!-- project title -->
            <v-card-title class="mb-0 font-weight-semi-bold">
              {{ project.title }}
            </v-card-title>

            <!-- project description -->
            <v-card-text class="project-description black--text">
              {{ project.description }}
            </v-card-text>

            <v-spacer/>

            <!-- link & status -->
            <v-row
              class="py-0 pr-0 pl-3"
              justify="space-between"
              align="end"
              no-gutters
            >
              <router-link
                class="primary--text project-description pb-3"
                :to="`/projects/${project.id}`"
              >
                > {{$t('projects.readMore')}}
              </router-link>

              <v-img
                class="ml-auto"
                src="@/assets/images/projects/status_leaf.svg"
                height="40px"
                max-width="185"
                min-width="185"
              >
                <v-row
                  no-gutters
                  justify="end"
                  align="center"
                  class="fill-height"
                >
                  <!-- status -->
                  <span class="status white--text mr-5">
                    {{$t(`projects.status.${project.status}`)}}
                  </span>
                </v-row>
              </v-img>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </v-container>
</template>

<script>
  import projects from '@/data/projects.json';

  export default {
    data: () => ({
      // heroSlides: vm.$t('home.hero').map((slideText, idx) => ({
      //   image: require(`@/assets/images/home/hero/hero_${idx}.png`),
      //   text: slideText
      // })),
      projects: []
    }),
    mounted() {
      this.projects = projects.map(project => ({
        ...project,
        ...this.$t(`projects.items.${project.id}`)
      }))
    }
  };
</script>

<style scoped>
  .project-description {
    font-size: 17px;
    line-height: 1.25;
  }

  .status {
    font-size: 15px;
  }
</style>
