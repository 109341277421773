import { render, staticRenderFns } from "./DataProtection.vue?vue&type=template&id=5b6116ba&scoped=true&"
import script from "./DataProtection.vue?vue&type=script&lang=js&"
export * from "./DataProtection.vue?vue&type=script&lang=js&"
import style0 from "./DataProtection.vue?vue&type=style&index=0&id=5b6116ba&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b6116ba",
  null
  
)

export default component.exports