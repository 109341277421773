<!--
 Name: AppHero.vue
 Description: Site hero component.
 Created by: Milena Malysheva on 2020-09-08
 Last edited by: Milena Malysheva on 2022-01-19
-->

<template>
  <v-container
    fluid
    class="hero-block pa-0 lightGrey3"
  >
    <!-- slidesLoaded + v-img v-if/load + v-row v-if - is a workaround to make the animation smooth when the
    slides are being loaded and rendered. It would be great if we could come up with a more elegant
    solution -->
    <template v-for="(slide, idx) in slides">
      <v-img
        v-if="!idx || slidesLoaded[idx - 1]"
        :key="idx"
        :src="slide.image"
        position="left"
        @load="$set(slidesLoaded, idx, true)"
        :class="{ 'fade-animation': idx !== slides.length - 1}"
        :style="{ animationDelay: `${(idx + 1) * 5}s`, zIndex: slides.length - idx }"
      >
        <v-row
          v-if="slidesLoaded[idx]"
          no-gutters
          justify="space-between"
        >
          <!-- slide text -->
          <v-col cols="12" lg="7">
            <!-- v-html to render soft hyphens -->
            <i18n-custom
              tag="div"
              class="padded-block pr-4 pt-16 mt-2 white--text hero-title text-with-shadow"
              :path="slide.textPath"
            />
          </v-col>
        </v-row>
      </v-img>
    </template>

    <!-- learn more button -->
    <v-row
      class="hidden-sm-and-down"
      no-gutters
      align="end"
      justify="center"
      :style="{ zIndex: slides.length }"
    >
      <v-btn
        @click="$vuetify.goTo('#top')"
        class="loadMoreBtn loadMoreBtnTxt--text mb-7"
      >
        {{$t('hero.learnMoreBtn')}}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    props: {
      slides: Array
    },
    data: () => ({
      slidesLoaded: []
    }),
    created() {
      this.slidesLoaded = new Array(this.slides.length).fill(false)
    }
  };
</script>

<style scoped>
  /* general */
  .hero-block {
    min-height: 690px;
    display: grid;
  }
  .text-with-shadow {
    text-shadow: 1px 1px 2px grey;
  }

  /* animation */
  .fade-animation {
    animation: fader 2s forwards;
  }
  @keyframes fader {
    from { opacity: 1.0; }
    to   { opacity: 0.0; }
  }

  /*allows 'learn more' button to be on top*/
  .hero-block > .row, .hero-block > .v-image {
    grid-column: 1;
    grid-row: 1;
  }

  /* fonts */
  .hero-title {
    font-size: 40px;
    line-height: 1.25;
  }
</style>