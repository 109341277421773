<!--
 Name: ProjectTemplate.vue
 Description: Project template component.
 Created by: Milena Malysheva on 2020-09-11
 Last edited by: Lokeswari Madhusudhana on 2024-10-29
-->

<template>
  <v-container fluid class="pa-0">
    <!-- hero -->
    <app-hero
      :slides="[{
        image: require(`@/assets/images/projects/hero/project_${project.id}.jpg`),
        textPath: `projects.items.${this.$route.params.id}.title`
      }]"
    >
    </app-hero>

    <!-- navigation toolbar -->
    <v-row
      class="duckBlueWater padded-block py-3 navigation-toolbar"
      align="center"
      no-gutters
    >

      <!-- previous project -->
      <v-col cols="4">
        <component
          :is="nextProjectId === null ? 'label' : 'router-link'"
          class="text-decoration-none"
          :class="nextProjectId === null ? 'grey2--text' : 'primary--text'"
          :to="`/projects/${nextProjectId}`"
        >
          <v-icon
            class="mr-3"
            large
          >
            mdi-chevron-left
          </v-icon>
          <span
            :class="{'text-decoration-underline': nextProjectId !== null}"
            class="hidden-sm-and-down">{{ $t("projects.navigation.nextProject") }}</span>
        </component>
      </v-col>

      <!-- all projects -->
      <v-col
        cols="4"
        class="text-center"
      >
        <router-link
          to="/projects"
          class="primary--text"
        >{{ $t("projects.navigation.allProjects") }}</router-link>
      </v-col>

      <!-- next project -->
      <v-col
        cols="4"
        class="text-right"
      >
        <component
          :is="prevProjectId === null ? 'label' : 'router-link'"
          class="text-decoration-none"
          :class="prevProjectId === null ? 'grey2--text' : 'primary--text'"
          :to="`/projects/${prevProjectId}`"
        >
          <span
            :class="{'text-decoration-underline': prevProjectId !== null}"
            class="hidden-sm-and-down">{{ $t("projects.navigation.previousProject") }}</span>
          <v-icon
            class="ml-3"
            large
          >
            mdi-chevron-right
          </v-icon>
        </component>
      </v-col>
    </v-row>

    <v-container class="pt-lg-16 pb-lg-8 py-6 padded-block text-center" id="top">
      <!-- project title -->
      <h1 class="font-weight-semi-bold title-font">
        {{ project.title }}
      </h1>

      <!-- project status -->
      <span class="status grey2--text">
        {{$t(`projects.status.${project.status}`)}}
      </span>
    </v-container>

    <!-- introduction -->
    <p class="subtitle-font padded-block text-md-justify">
      {{ project.description }}
    </p>

    <!-- BTN: links -->
    <v-row
      justify="center"
      no-gutters
      class="pt-6 pb-12 padded-block"
      v-if="project.blogArticleId"
    >

      <!-- blog article -->
      <v-col cols="10" sm="7" md="4">
        <v-btn
          x-large
          outlined
          rounded
          color="primary"
          block
          height="60"
          :ripple="false"
          :to="`/blog/${project.blogArticleId}`"
        >
          {{ $t("projects.readArticleBtn") }}
        </v-btn>
      </v-col>

      <!-- another link might be added here -->
    </v-row>

    <!-- timeline (large devices)-->
    <div class="padded-block hidden-sm-and-down py-4">
      <v-img
        src="@/assets/images/projects/timeline/timeline.png"
        aspect-ratio="20"
        contain
      >
        <v-row align="center" class="ml-2 my-0">

          <!-- start -->
          <v-col cols="4" class="white--text">
            <div class="font-weight-semi-bold">
              {{ $t("projects.dates.start") }}
            </div>
            <div>
              {{ project.dates.start }}
            </div>
          </v-col>

          <!-- evaluation -->
          <v-col cols="4" class="white--text">
            <div class="font-weight-semi-bold">
              {{ $t("projects.dates.evaluation") }}
            </div>
            <div>
              {{ project.dates.evaluation }}
            </div>
          </v-col>

          <!-- publication -->
          <v-col cols="4" class="white--text">
            <div class="font-weight-semi-bold">
              {{ $t("projects.dates.publication") }}
            </div>
            <div>
              {{ project.dates.publication }}
            </div>
          </v-col>
        </v-row>
      </v-img>
    </div>

    <!-- timeline (small devices) -->
    <v-row class="padded-block hidden-md-and-up pb-4" no-gutters justify="center">

      <v-col cols="auto">
        <v-img
          src="@/assets/images/projects/timeline/mobile/piece0.png"
          width="63"
          contain
        />

        <!-- start -->
        <v-row no-gutters>
          <v-col cols="auto">
            <v-img
              src="@/assets/images/projects/timeline/mobile/piece1.png"
              width="63"
              contain
            />
          </v-col>
          <v-col>
            <div class="font-weight-semi-bold">
              {{ $t("projects.dates.start") }}
            </div>
            {{ project.dates.start }}
          </v-col>
        </v-row>

        <!-- evaluation -->
        <v-row no-gutters>
          <v-col cols="auto">
            <v-img
              src="@/assets/images/projects/timeline/mobile/piece2.png"
              width="63"
              contain
            />
          </v-col>
          <v-col>
            <div class="font-weight-semi-bold">
              {{ $t("projects.dates.evaluation") }}
            </div>
            {{ project.dates.evaluation }}
          </v-col>
        </v-row>

        <!-- publication -->
        <v-row no-gutters>
          <v-col cols="auto">
            <v-img
              src="@/assets/images/projects/timeline/mobile/piece3.png"
              width="63"
              contain
            />
          </v-col>
          <v-col>
            <div class="font-weight-semi-bold">
              {{ $t("projects.dates.publication") }}
            </div>
            {{ project.dates.publication }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- project info -->
    <!-- :value - to open all the tabs in the section by default (on the large devices) -->
    <v-expansion-panels
      accordion
      focusable
      tile
      multiple
      class="padded-block pt-6 mb-13"
      :value="$vuetify.breakpoint.lgAndUp ? Array.from(Array(project.projectInfo.length).keys()) : []"
    >
      <v-expansion-panel
        v-for="(item, projectInfoIdx) in project.projectInfo"
        :key="item.question"
      >
        <v-expansion-panel-header
          class="font-weight-semi-bold py-3"
        >
          {{ item.question }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <i18n-custom
            paragraph-class="mt-2 mb-0"
            :path="`projects.items.${project.id}.projectInfo.${projectInfoIdx}.answer`"
            tag="p"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-container class="padded-block pb-14">
      <v-row
        class="mx-lg-16"
        justify="space-around"
        align="center"
        no-gutters
      >
        <!-- we handle the logos individually to be able to set their width and height -->
        <v-img
          v-if="project.partners.includes('dzne')"
          class="my-2"
          max-height="70"
          max-width="150"
          contain
          src="@/assets/images/projects/partners/dzne.png"
        />

        <v-img
          v-if="project.partners.includes('ovgu')"
          class="my-2"
          max-height="70"
          max-width="150"
          contain
          src="@/assets/images/projects/partners/ovgu.png"
        />

        <v-img
          v-if="project.partners.includes('iknd')"
          class="my-2"
          max-height="60"
          max-width="150"
          contain
          src="@/assets/images/projects/partners/iknd.png"
        />

        <v-img
          v-if="project.partners.includes('sana')"
          class="my-2"
          max-height="80"
          max-width="40"
          contain
          src="@/assets/images/projects/partners/sana.png"
        />

        <v-img
          v-if="project.partners.includes('neotiv')"
          class="my-2"
          max-height="50"
          max-width="150"
          contain
          src="@/assets/images/projects/partners/neotiv.png"
        />
      </v-row>
    </v-container>

    <!-- participation (planned & ongoing projects) -->
    <v-container
      class="padded-block duckBlueMid2 pt-10 pb-13"
      fluid
      v-if="project.status === 'planned' || project.status === 'ongoing'"
    >
      <!-- caption -->
      <p class="title-font font-weight-semi-bold text-center pb-5">
        {{$t("projects.participation.title")}}
      </p>

      <!-- description -->
      <div class="text-center text-md-justify subtitle-font">
        {{$t("projects.participation.description")}}
        <!-- faq-link -->
        <i18n path="projects.participation.faqLinkText" v-if="project.faq">
          <template #faq-link>
            <a
              class="text-decoration-underline"
              @click="$vuetify.goTo('#faq')">{{ $t("projects.participation.faqLink") }}</a>
          </template>
        </i18n>
      </div>

      <!-- step 1 -->
      <v-img
        class="my-3"
        src="@/assets/images/projects/step_1.svg"
        height="50px"
        contain
      />
      <p
        class="subtitle-font primary--text text-center"
        v-text="$t(`projects.participation.steps.${project.openParticipation ? 'openParticipation' : 'withAccessCode'}.0`)"
      />

      <!-- step 2 -->
      <v-img
        class="my-3"
        src="@/assets/images/projects/step_2.svg"
        height="50px"
        contain
      />
      <p
        class="subtitle-font primary--text text-center"
        v-text="$t(`projects.participation.steps.${project.openParticipation ? 'openParticipation' : 'withAccessCode'}.1`)"
      />

      <!-- BTN: participate -->
      <v-row
        justify="center"
        class="pt-6"
      >
        <v-col cols="10" sm="7" md="4">
          <!-- BTN: load the app -->
          <v-btn
            v-if="project.openParticipation"
            x-large
            outlined
            rounded
            color="primary"
            block
            height="60"
            :ripple="false"
            to="/#app"
          >
            {{ $t("projects.participation.loadAppBtn") }}
          </v-btn>
          <!-- BTN: contact -->
          <!-- <v-btn
            v-else
            x-large
            outlined
            rounded
            color="primary"
            block
            height="60"
            :ripple="false"
            :to="{ name: 'Contact', params: {
              projectId: project.id
            } }"
          >
            {{ $t("projects.participation.contactBtn") }}
          </v-btn> -->
        </v-col>
      </v-row>

    </v-container>

    <!-- faq -->
    <template v-if="project.faq">

      <!-- caption -->
      <p class="title-font font-weight-semi-bold text-center py-10 padded-block" id="faq">
        {{$t("projects.faq")}}
      </p>

      <!-- items -->
      <v-expansion-panels
        accordion
        focusable
        tile
        multiple
        class="padded-block pb-14"
      >
        <v-expansion-panel
          v-for="(item, faqIdx) in project.faq"
          :key="item.question"
        >
          <v-expansion-panel-header
            class="font-weight-semi-bold py-3"
          >
            {{ item.question }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <i18n-custom
              paragraph-class="mt-2 mb-0"
              :path="`projects.items.${project.id}.faq.${faqIdx}.answer`"
              tag="p"
            >
              <template #phases>
                <div
                  v-for="(phase, phaseIdx) in item.phases"
                  :key="`projects.items.${project.id}.faq.${faqIdx}.phases.${phaseIdx}`"
                  class="mt-2"
                >
                  <span class="font-weight-semi-bold">{{ phase.name }}</span>:
                  {{ phase.info }}
                </div>
              </template>
            </i18n-custom>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </v-container>
</template>

<script>
  import projects from '@/data/projects.json';

  export default {
    data: () => ({
      project: null,
      prevProjectId: null,
      nextProjectId: null
    }),
    created() {
      const currentProjectIdx = projects.findIndex(project => parseInt(this.$route.params.id, 10) === project.id)
      this.nextProjectId = currentProjectIdx ? projects[currentProjectIdx - 1].id : null
      this.prevProjectId = currentProjectIdx === projects.length - 1 ? null : projects[currentProjectIdx + 1].id

      this.project = {
        ...projects[currentProjectIdx],
        ...this.$t(`projects.items.${this.$route.params.id}`)
      }
    }
  };
</script>

<style scoped>
  .navigation-toolbar a{
    cursor: pointer;
  }
  .navigation-toolbar a:hover{
    opacity: 0.6;
  }
  .navigation-toolbar .v-icon {
    color: inherit;
  }

  .status {
    font-size: 19px;
  }

  /*overriding vuetify expansion panel styles*/
  .v-expansion-panel::before {
    box-shadow: none !important;
  }
  .v-expansion-panel:first-child {
    border-top: thin solid rgba(0, 0, 0, 0.12);
  }
  .v-expansion-panel:last-child {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
  .v-expansion-panel-header--active {
    background-color: var(--v-duckBlueWater-base);
  }
  .v-expansion-panel-header:hover {
    background-color: var(--v-duckBlueWater-base);
  }
  .v-expansion-panel-header--active::before {
    opacity: 0!important;
  }
  .v-expansion-panel-content {
    line-height: 1.45;
  }
  >>>.v-expansion-panel-content>.v-expansion-panel-content__wrap{
    padding-top: 8px;
    padding-bottom: 20px;
  }
</style>
