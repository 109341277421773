<!--
 Name: Contact.vue
 Description: Component for the contact us page.
 Created by: Milena Malysheva on 2020-09-08
 Last edited by: Milena Malysheva on 2020-09-08
-->

<template>
  <div class="lightGreen fill-height align-start">
    <v-container fluid class="py-10 padded-block darkGrey--text">
      <!-- page title -->
      <h1 class="text-center pb-9 font-weight-semi-bold title-font">
        {{$t('contact.title')}}
      </h1>

      <!-- intro -->
      <p class="text-center pb-7 font-weight-medium subtitle-font large-line-height">
        {{$t('contact.intro')}}
      </p>

      <contact-form/>
    </v-container>
  </div>
</template>

<script>
  import ContactForm from "@/components/ContactForm";

  export default {
    components: {
      ContactForm
    }
  };
</script>

<style scoped>
</style>
