/**
 * Name: main.js
 * Description: Main entry point.
 * Created by: Milena Malysheva on 2020-09-03
 * Last edited by: Lokeswari Madhusudhana on 2023-09-12
 */

import Vue from 'vue'
import App from './App.vue'
import i18n from './plugins/i18n'
import router from './plugins/router'
import vuetify from './plugins/vuetify';

import AppHero from "./components/ui/AppHero";
import AppInfoBlock from "./components/ui/AppInfoBlock";
import I18nCustom from "./components/utils/I18nCustom";
import axiosInstance from './api';

Vue.component('app-hero', AppHero);
Vue.component('app-info-block', AppInfoBlock);
Vue.component('i18n-custom', I18nCustom);

Vue.config.productionTip = false
Vue.prototype.$axios = axiosInstance

new Vue({
 i18n,
 router,
 vuetify,
 render: h => h(App)
}).$mount('#app')
