<!--
 Name: TheSideMenu.vue
 Description: Side menu for small devices.
 Created by: Milena Malysheva on 2020-09-03
 Last edited by: Milena Malysheva on 2021-11-03
-->

<template>
  <v-navigation-drawer
    v-model="openSideMenuLocal"
    width="220"
    app
    fixed
    right
    temporary
    height="100%"
    hide-overlay
  >
    <v-list>
      <router-link
        v-for="link in menuLinks"
        :key="link"
        :to="`/${link}`"
        class="text-decoration-none"
      >
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{$t(`siteMenu.${link}`)}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </router-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    props: {
      openSideMenu: Boolean,
      menuLinks: Array
    },
    data: () => ({
      openSideMenuLocal: false
    }),
    watch: {
      openSideMenu(value) {
        if (value) this.openSideMenuLocal = true
      },
      openSideMenuLocal(value) {
        if (!value) this.$emit('update:openSideMenu', false)
      }
    }
  };
</script>
