<!--
 Name: NewsBanner.vue
 Description: Component for the animated news banner.
 Created by: Milena Malysheva on 2021-07-09
 Last edited by: Milena Malysheva on 2021-10-06
-->

<template>
  <router-link
    to="projects/1"
    tag="div"
    class="news-banner white--text duckBlueDark"
  >
    <v-row align="center" no-gutters class="pr-md-7">
      <v-img
        class="hidden-sm-and-down"
        height="60"
        max-width="70"
        contain
        src="@/assets/images/home/news_banner/banner_ribbon.svg"
      />
      <v-col class="overflow-hidden" ref="banner">
        <div
          ref="bannerText"
          :class="$vuetify.breakpoint.mdAndDown ? 'pa-4 pa-md-0 text-center': 'running-text'"
        >
          <div
            ref="bannerTextBlock"
            class="d-inline-block"
            v-for="n in textBlockCount"
            :key="`banner-text-block-${n}`"
          >
            <span>{{ $t('home.newsBanner') }}</span>
          </div>
        </div>
      </v-col>
    </v-row>
  </router-link>
</template>

<script>
  export default {
    data: () => ({
      textBlockCount: 1,
      textBlockRO: null,
      bannerAnimation: null,
    }),
    methods: {
      initBanner () {
        if (this.$vuetify.breakpoint.mdAndDown) {
          this.textBlockCount = 1
        } else {
          this.textBlockCount = Math.ceil(this.$refs.banner.clientWidth / this.$refs.bannerTextBlock[0].clientWidth) + 1
        }
      }
    },
    watch: {
      textBlockCount(val) {
        if (this.bannerAnimation) this.bannerAnimation.cancel()
        if (val > 1) this.bannerAnimation = this.$refs.bannerText.animate(
          [
            { transform: 'translateX(0%)'},
            { transform: `translateX(-${ 100 / val }%)`},
          ], {
            duration: 22000,
            iterations: Infinity
          }
        );
      }
    },
    mounted() {
      // v-resize directive can't be used to detect the font being loaded event ->
      // -> this.$refs.bannerTextBlock[0].clientWidth value is not precise
      this.textBlockRO = new ResizeObserver(() => {
        this.initBanner();
      });

      this.textBlockRO.observe(this.$refs.banner)
      this.textBlockRO.observe(this.$refs.bannerTextBlock[0])
    },
    beforeDestroy () {
      this.textBlockRO.unobserve(this.$refs.banner)
      this.textBlockRO.unobserve(this.$refs.bannerTextBlock[0])
    }
  };
</script>

<style scoped>
  .news-banner{
    transition: background-color 0.2s linear;
    cursor: pointer;
  }

  .news-banner:hover{
    background-color: var(--v-cookieBannerHover-base)!important;
  }

  .running-text {
    width: fit-content;
    white-space: nowrap;
  }

  .running-text > div > span{
    padding-right: 1400px;
  }
</style>