<!--
 Name: PageNotFound.vue
 Description: 404 error page component.
 Created by: Milena Malysheva on 2020-09-03
 Last edited by: Milena Malysheva on 2020-10-15
-->

<template>
  <div class="duckBlueMid2 fill-height align-start">
    <v-container fluid class="py-10 padded-block">

      <!-- fish (o.O)-->
      <v-img
        src="@/assets/images/error/fish.svg"
        aspect-ratio="1"
        max-height="300"
        contain
      />

      <!-- error text -->
      <p class="text-center pt-12 title-font">
        {{$t('error.text')}}
      </p>

      <!-- BTN: back to the landing page -->
      <v-row justify="center" no-gutters class="pt-10 pb-16">
        <v-col cols="10" sm="6" md="3">
          <v-btn
            x-large
            outlined
            rounded
            color="primary"
            block
            height="60"
            :ripple="false"
            to="/"
          >
            {{ $t("error.backHomeBtn") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  export default {};
</script>

<style scoped>
</style>
