/**
 * Name: vuetify.js
 * Description: Vuetify configuration file.
 * Created by: Milena Malysheva on 2020-09-03
 * Last edited by: Milena Malysheva on 2020-11-25
 */

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        // colors provided by Cecile (https://confluence.neotiv.com/pages/viewpage.action?pageId=51218170)
        primary: '#00869A',
        duckBlueLight: '#85C1C8',
        duckBlueMid: '#4EABB9',
        duckBlueMid2: '#C5E2E9',
        duckBlueWater: '#DDEFF2',
        duckBlueDark: '#1A6679',
        bleuCanard: '#008AA1',
        cookieBannerHover: '#86C6CF',
        loadMoreBtn: '#2999AA',
        loadMoreBtnTxt: '#bfe1e6',
        lightGreen: '#DAEDF1',
        fieldGrey: '#575756',
        grey: '#CCCCCC',
        grey2: '#8C8C8C',
        lightGrey: '#F5F5F5',
        lightGrey2: '#EEEEEE',
        lightGrey3: '#E3E3E3',
        darkGrey: '#414141',
        darkGrey2: '#B5B5B5',

        // colors copied from the website project (neotiv.com)
        lightGray: '#EFEFEF',

        // color copied from the neotivCare project (neotiv-care.com)
        popupGreyBg: '#1D1D1B',
        cookieBannerText: "#8B8B8B",

        // utils
        error: '#BF2329',
        anchor: '#000000'
      }
    }
  }
});
