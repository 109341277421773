<!--
 Name: Legal.vue
 Description: Component for the legal page.
 Created by: Milena Malysheva on 2020-09-08
 Last edited by: Milena Malysheva on 2022-01-19
-->

<template>
  <v-container fluid class="pa-0 mb-12">
    <div class="padded-block">
      <!-- page title -->
      <h1 class="py-10 font-weight-semi-bold title-font" id="top">
        {{$t('footer.legal')}}
      </h1>

      <!-- general info -->
      <div
        v-for="(block, idx) in $t('legal.info')"
        :key="`legal.info.${idx}`"
        class="pb-4"
      >
        <h2 class="subtitle-font font-weight-semi-bold">
          {{block.title}}
        </h2>

        <i18n-custom
          :path="`legal.info.${idx}.text`"
          tag="p"
          class="large-line-height pt-1"
        >
          <!-- contact email -->
          <template #contact-email>
            <a
              :href="`mailto:${$t(`legal.info.${idx}.contactEmail`)}`"
              class="bleuCanard--text"
            >{{ $t(`legal.info.${idx}.contactEmail`) }}</a>
          </template>
        </i18n-custom>
      </div>

      <!-- disclaimer -->
      <p class="py-2 font-weight-semi-bold title-font">
        {{$t('legal.disclaimer.title')}}
      </p>
      <div
        v-for="(block, idx) in $t('legal.disclaimer.content')"
        :key="`legal.disclaimer.${idx}`"
        class="pb-4"
      >
        <h2 class="subtitle-font font-weight-semi-bold">
          {{block.title}}
        </h2>
        <p class="large-line-height pt-1">
          {{block.text}}
        </p>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {};
</script>
