<!--
 Name: TheFooter.vue
 Description: Site footer component.
 Created by: Milena Malysheva on 2020-09-03
 Last edited by: Milena Malysheva on 2021-11-03
-->

<template>
  <v-footer id="app-footer" class="px-0 darkGrey2">
    <v-container fluid class="footer-padded-block white--text text-center pb-6">
      <v-row justify="center" class="my-0">
        <v-col cols="12" sm="8" md="5">
          <v-img
            height="90"
            contain
            src="@/assets/images/logo_white.svg"
          />
        </v-col>
      </v-row>

      <!-- description -->
      <p>
        {{ $t('footer.description') }}
      </p>

      <!-- copyright notice / footer menu-->
      <v-row
        class="mt-5"
        justify="center"
        no-gutters
      >
        {{ new Date().getFullYear() }} {{ $t('footer.copyright') }}

        <v-col
          cols="12"
          md="auto"
          class="mt-md-0 mt-1"
        >
          <!-- see requirements https://confluence.neotiv.com/pages/viewpage.action?pageId=51222407 -->
          <span class="mx-2 hidden-sm-and-down">
            -
          </span>

          <template
            v-for="(link, idx) in footerLinks"
          >
            <span
              :key="link"
              class="mx-2"
              v-if="idx"
            >
              |
            </span>
            <router-link
              :key="`link-${link}`"
              :to="`/${link}`"
              class="font-weight-semi-bold router-link white--text text-decoration-none"
            >{{$t(`footer.${link}`)}}</router-link>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      footerLinks: [
        'contact',
        'legal',
        'data'
      ]
    })
  };
</script>

<style scoped lang="scss">
  @import '~vuetify/src/styles/styles.sass';

  /* footer */
  #app-footer {
    font-size: 17px;
    line-height: 1.4;
  }

  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    .footer-padded-block {
      padding-left: 250px;
      padding-right: 250px;
    }
  }
  @media #{map-get($display-breakpoints, 'md-and-down')} {
    .footer-padded-block {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  /* router links */
  .router-link:hover{
    color: var(--v-lightGrey3-base)!important;
  }
</style>