<!--
 Name: Blog.vue
 Description: Blog component.
 Created by: Milena Malysheva on 2020-09-17
 Last edited by: Milena Malysheva on 2021-11-03
-->

<template>
  <v-container fluid class="pt-0 pb-7 padded-block">

    <!-- page title -->
    <h1 class="pt-lg-16 pb-lg-12 py-6 font-weight-semi-bold title-font text-center" id="top">
      {{$t('siteMenu.blog')}}
    </h1>

    <!-- introduction -->
    <p class="text-center pb-5 font-weight-medium subtitle-font large-line-height">
      {{$t('blog.intro')}}
    </p>

    <!-- search -->
    <v-row
      class="mb-10"
      justify="center"
    >
      <!-- search bar -->
      <v-col cols="12" md="4">
        <v-text-field
          class="search-field"
          v-model="search"
          :label="$t('blog.search')"
          append-icon="mdi-magnify"
          color="darkGrey2"
          clearable
          outlined
          single-line
          hide-details
        />

        <!-- search results -->
        <div
          v-show="search"
          class="darkGrey2--text font-weight-semi-bold mt-1"
        >
          {{ $t("blog.articlesFound") }} : {{ filteredArticles.length }}
        </div>
      </v-col>
    </v-row>

    <!-- articles -->
    <app-info-block
      class="pb-12"
      v-for="article in filteredArticles"
      :key="article.id"
      :block-title="article.title"
      :block-sub-title="`${article.author} · ${article.date}`"
    >
      <template #text-content>
        <p class="mb-3">
          {{ article.content | customTruncate }}
        </p>
        <router-link
          :to="`blog/${article.id}`"
          class="primary--text"
        >
          > {{ $t("blog.readArticle") }}
        </router-link>
      </template>
    </app-info-block>

  </v-container>
</template>

<script>
  import articles from '@/data/articles.json';

  export default {
    data: () => ({
      articles: [],
      search: ''
    }),
    computed: {
      filteredArticles () {
        return this.articles.filter(article => {
          return (
            article.author.toLowerCase().includes(this.search.toLowerCase()) ||
            article.date.toLowerCase().includes(this.search.toLowerCase()) ||
            article.title.toLowerCase().includes(this.search.toLowerCase()) ||
            article.content.join(' ').toLowerCase().includes(this.search.toLowerCase())
          )
        })
      }
    },
    filters: {
      customTruncate: function (content) {
        let wordsArray = content.join(' ').split(' ')
        let description = wordsArray.shift()
        for (let word of wordsArray) {
          if (description.length + word.length + 1 < 300) {
            description += ' ' + word
          } else {
            break
          }
        }
        return description + '...'
      }
    },
    mounted() {
      this.articles = articles.map(article => ({
        ...article,
        ...this.$t(`blog.articles.${article.id}`)
      }))
    }
  };
</script>
